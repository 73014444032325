<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.76 9.33">
    <polyline
      points=".5 6.24 3.38 8.83 6.26 6.24"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.38"
      y1="8.83"
      x2="3.38"
      y2=".5"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
